@use '@/styles/Colors.module.scss';

.date-picker-field {
  @apply relative w-full overflow-visible mb-[16px];
  input {
    @apply px-4 py-3 text-body leading-1;
  }
}
.wrapper {
  @apply block relative w-full;
}
.calendar-container {
  @apply flex flex-row-reverse;
}
.input {
  @apply w-full border border-fieldBorder rounded-1 text-slate font-[var(--fonts-default)];
  outline: none;
  &:focus-visible,
  &.focus {
    outline: 2px solid Colors.$blue;
  }
  &.error {
    @apply text-red;
    border: 2px solid Colors.$red;
  }
  &:disabled {
    background-color: var(--colors-gray-light);
    cursor: not-allowed;
  }
}