@import 'Colors.module.scss';

.badge {
  padding: 6px 16px;
  width: fit-content;
  border-radius: 99px;
  margin-bottom: 0;
  margin-right: 10px;
  &.plan {
    padding: 3px 8px;
  }
  &.blue {
    border-width: 2px;
    border-color: $blue;
  }
  &.white {
    border-width: 2px;
    border-color: white;
  }
  &.green {
    border-width: 2px;
    border-color: $green;
  }
  &.yellow {
    border-width: 2px;
    border-color: $yellow;
  }
}

.countBadge {
  position: absolute;
  border-radius: 100%;
  background-color: $blue;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  height: 24px;
  width: 24px;
  top: -0.5em;
  right: -0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

