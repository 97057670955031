.main {
  padding: 0 30px;
  height: 100%;
}
.dialog {
  width: 470px;
  left: calc(100vw - 470px) !important;
  position: fixed;
}
.headingText {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.submitBtn {
  background-color: #25C955 !important;
  color: #fff;
  border-radius: 99px !important;
  border: none;
  height: 44px;
  font-size: 16px;
  font-weight: 700;
}

.slide-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.slide-content {
  flex: none;
  overflow-y: auto;
  padding-bottom: 100px;
}

.slide-content-mobile {
  flex: none; 
  padding-bottom: 80px;
}

.slide-action-buttons {
  background-color:  #ffffff;
  display: flex;
  padding: 30px 0px;
  width: 100%;
  max-width: 410px;
  position: fixed;
  bottom: 0;
}

.slide-action-buttons-mobile {
  background-color:  #ffffff;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  max-width: 410px;
}

.slide-action-buttons button + button {
  margin-left: 20px;
}

.slide-action-buttons-mobile button + button {
  margin-left: 0;
  margin-top: 20px;
}

@media only screen and (max-device-width: 600px) {
  .dialog {
    width: 100%;
    position: fixed;
    left: 0 !important;
    right: 0 !important;
    top: 80px !important;
    bottom: 0 !important;
  }
}
