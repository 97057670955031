.listBoxButton {
  @apply relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-dark ring-1 ring-inset ring-gray-light focus:outline-none focus:ring-2 focus:ring-blue sm:text-sm sm:leading-6;
}
.listboxOptions {
  @apply absolute z-40 mt-0 max-h-[200px] w-full overflow-auto rounded-md shadow-lg bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm;
}
.listboxOption {
  @apply relative cursor-pointer select-none px-4 py-2 bg-white text-gray-dark data-[focus]:bg-gray-light data-[focus]:text-white aria-selected:bg-gray-light;
}
.listboxOptionSelected,
.listboxOptionActive {
  @apply bg-gray-light;
}
