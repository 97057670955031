@import 'Colors.module.scss';

.h1 {
  font-weight: bold;
  font-size: 96px;
  line-height: 120%;
  letter-spacing: -0.026em;
}
.h2 {
  font-weight: bold;
  font-size: 60px;
  line-height: 120%;
  margin-bottom: 16px;
}
.h3 {
  font-size: 48px;
  line-height: 124%;
  font-weight: bold;
  letter-spacing: -0.01em;
}
.h4 {
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 128%;
}
.h5 {
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
}
.h6 {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 150%;
  letter-spacing: 0.0075em;
}
.small {
  font-size: 14px;
}
.large {
  font-size: 20px;
}
.bold {
  font-weight: bold;
}
.smallBold {
  font-size: 14px;
  font-weight: bold;
}
.error {
  font-size: 12px;
  color: #E85757;
}

.regular {
  font-size: 1rem;
  font-weight: 400;
}
@mixin truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.textLink {
  color: $blue;
  font-weight: bold;
  &:hover {
    color: $twilight;
  }
  &:active {
    color: $twilight;
  }
  &:visited {
    color: $twilight;
  }
}
