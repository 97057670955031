$midnight: #051336;
$twilight: #202b7e;
$blue: #005bff;
$green: #25c955;
$yellow: #ffc165;
$red: #e85757;
$slate: #1d1d1d;
$gray-dark: #757575;
$gray-light: #ececec;
$white: #ffffff;

.midnight {
  color: $midnight;
}

.twilight {
  color: $twilight;
}

.blue {
  color: $blue;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.red {
  color: $red;
}

.slate {
  color: $slate;
}

.gray-dark {
  color: $gray-dark;
}

.gray-light {
  color: $gray-light;
}

.white {
  color: $white;
}

:export {
  //* imported into JS as e.g. `Colors['theme-midnight']
  theme: {
    midnight: $midnight;
    twilight: $twilight;
    blue: $blue;
    green: $green;
    yellow: $yellow;
    red: $red;
    slate: $slate;
    gray-dark: $gray-dark;
    gray-light: $gray-light;
    white: $white;
  }
}
