@use '_base.scss';
@use 'Colors.module.scss';
@use 'Typography.module.scss';

$landscape-status-w: 120px;
$landscape-entryName-w: 180px;
$landscape-notes-w: 1fr;
$landscape-cf-w: 56px;
$landscape-actions-w: 244px;

$portrait-status-w: 206px;
$portrait-notes-w: $landscape-notes-w;
$portrait-cf-w: $landscape-cf-w;
$portrait-actions-w: 112px;

// Header components

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.newAddGuestButton {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.75em;

  background: Colors.$green;
  color: white;
  font-weight: 700;
  font-size: 1.5em;
  border: 1px solid Colors.$green;
  border-radius: 99px;
  box-sizing: border-box;
}

.headerRefreshBtn {
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-self: center;
}

.spin {
  animation: spin 2s infinite linear;
}

// Page layout

.pageContainer {
  padding: 16px;
}

// Waitlist cells or "entries"

.entryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 2fr 1fr;
  grid-template-areas:
  "one two three four"
  "one two three four"
  "five five five five";
  column-gap: 16px;
  margin-top: 8px;
  padding: 8px 16px;
  justify-content: space-between;
  border-radius: 8px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: Colors.$twilight;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
    overflow: hidden;
  }
  
  .corner-badge__isBooking {
    display: block;
    position: absolute;
    top: -10px;
    right: -20px;
    width: 0;
    height: 0;
    border-width: 0 30px 32px 30px;
    border-color: transparent transparent Colors.$blue transparent;
    border-radius: 8px 0 0 0;
    transform: rotate(42deg);
  }
  
  .corner-badge__icon-wrapper {
    display: block;
    position: absolute;
    top: 13px;
    right: -10px;
    height: 16px;
    width: 16px;
    transform: rotate(-42deg);
}
    
// Conditional cell styling based on status

.entryContainer-cancelled,
.entryContainer-cancelled_by_guest,
.entryContainer-no_show,
.entryContainer-walked_out {
  border-left-color: Colors.$red !important;
}
.entryContainer-waiting {
  border-left-color: Colors.$twilight !important;
}

.entryContainer-confirmed,
.entryContainer-scheduled {
  border-left-color: Colors.$blue !important;
}

.entryContainer-completed {
  border-left-color: Colors.$gray-dark !important;
}

.entryContainer-notified {
  border-left-color: Colors.$yellow !important;
}

.entryContainer-checked_in,
.entryContainer-returning {
  border-left-color: Colors.$green !important;
}

.entryContainer-in_service {
  border-left-color: Colors.$blue !important;
}

// Gray out "History" statuses to more easily distinguish between pages

.entryContainer-cancelled,
.entryContainer-no_show,
.entryContainer-walked_out,
.entryContainer-in_service,
.entryContainer-completed {
  background-color: Colors.$gray-light;
  box-shadow: none;
}


.entryStatus-cancelled,
.entryStatus-cancelled_by_guest,
.entryStatus-no_show,
.entryStatus-walked_out,
.entryStatus-danger {
  color: Colors.$red;
}

.entryStatus-waiting {
  color: Colors.$twilight;
}
.entryStatus-confirmed,
.entryStatus-scheduled {
  color: Colors.$blue;
}

.entryStatus-completed,
.entryStatus-success {
  color: Colors.$gray-dark;
}

.entryStatus-notified {
  color: Colors.$yellow;
}

.entryStatus-checked_in,
.entryStatus-returning {
  color: Colors.$green;
}

.entryStatus-in_service {
  color: Colors.$blue;
}

// Guest Info Section

.entryGuestSection {
  grid-area: one;
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 180px;
}

.entryName {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.entryPhone { // Removed from cell but keeping in case ever want to add it back
  font-size: 16px;
  font-weight: 500;
  color: Colors.$gray-dark;
  margin-top: 4px;
}

.entryQueueName {
  color: Colors.$slate;
  font-size: 16px;
  font-weight: 500;
  @include Typography.truncated;
  margin-top: 4px;
  position: relative;
  overflow: visible;
  max-height: 27px;
}

.entryStatus {
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  margin-top: 4px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entryNotifyCount {
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: Colors.$white;
  background-color: Colors.$yellow;
  border-radius: 50px;
  padding: 0px 8px;
  margin-left: 6px;
  width: fit-content;
}

// Custom Fields Section

.entryFieldsSection {
  grid-area: two;
  display: flex;
  align-self: center;
  overflow: auto;
  max-height: 200px;
  margin: 8px 0px;
}

.entryNotes { // Removed from cell but keeping in case ever want to add it back
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 500;
  color: Colors.$gray-dark;
  align-items: center;
}

.entrySingleField {
  margin: 0;
  color: Colors.$slate;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;

  .entrySingleFieldTitle {
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .entrySingleFieldValueBlock {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    padding: 8px 16px;
    display: flex;
    column-gap: 4px;
    row-gap: 4px;
    background-color: Colors.$gray-light;
    border-radius: 20px;
    width: fit-content;
    align-items: center;
  }

  .entrySingleFieldValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Wait time Section

.entryWaitSection {
  grid-area: three;
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-right: 8px;
}

.entryWaitTime {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.entryPosition {
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.entryJoinSpan {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
}

.entryJoinTime {
  font-size: 14px;
  font-weight: 500;
  color: Colors.$gray-dark;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.entryJoinSource {
  width: 16px;
  height: 16px;
  display: flex;
  color: Colors.$blue;
  margin-right: 8px;
  align-items: center;
}

// To do, clean up if we decide we don't need overflow custom fields

.entryCustomFields {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  padding-top: 4px;
}

.cfPill {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #f2f2f2;
}

.guestCustomFieldItem {
  font-size: 0.875rem;
}
.guestCustomFieldItemLabel {
  font-weight: 700;
}

.entryCfActionHamburger {
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 0.625rem;
  background-color: Colors.$gray-light;
  border-radius: 50%;
}

.entryCfActionCount {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: Colors.$red;
  color: Colors.$white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  right: -5px;
  top: -5px;
}

// Actions Section for Desktop/Tablet

.entryActionsSection {
  grid-area: four;
  display: flex;
  min-width: 220px;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: center;
  gap: 8px;
}

.entryActionButton {
  display: block;
  border: 0;
  border-radius: 8px;
  height: 48px !important;
  padding: calc(24px - 0.5rem);
  font-size: 12px;
    @media screen and (max-width: 1200px) {
      padding-left: calc(24px - 0.5rem);
      padding-right: calc(24px - 0.5rem);
    }
    // add small margin on tablet because some versions of chrome on tablet
    // do not support flexbox gap
    @media screen and (min-width: 600px) and (max-width: 1366px) {
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1800px) {
      padding-top: calc(24px - 0.688rem);
      padding-bottom: calc(24px - 0.688rem);
    }
  }

  
  .visibleAllBreakpoints {
    display: block !important;
  }
  

// Repeat Guest Lookup for Add Guest Form

.partyFormLoyaltyLookup {
  position: absolute;
  top: 2.25rem;
  right: 1rem;
  z-index: 1;
}
.partyFormLoyaltySpinner {
  margin-top: 3px;
  color: Colors.$green;
}
.partyFormLoyaltySuccess {
  display: inline-flex;
  color: var(--colors-green);
  font-weight: 600;
  font-size: 0.875rem;
}
.partyFormLoyaltyFailure {
  color: Colors.$blue;
  font-weight: 600;
  font-size: 0.875rem;
}
.partyFormLookupData {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

// Responsive sizing - order of queries matters
// container queries for wl action buttons

@media screen and (max-width: 1799px) {
  .entryAction {
    img {
      display: block;
    }

    span {
      display: none;
    }
  }
}

@media screen and (min-width: 1800px) {
  .entryAction {
    img {
      display: none;
    }

    span {
      display: block;
    }
  }
}

@media screen and (max-width: 1509px) {
  .entryAction {
    img {
      display: block;
    }

    span {
      display: none;
    }
  }
}

@include base.for-phone-only {
  // @media (max-width: 600px) {
  .main {
    padding-right: 20px;
    /*min-width: calc(100vw - 70px);*/
  }
  .addGuestBtnSmall,
  .disabledAddGuestBtnSmall {
    width: 40px;
    height: 40px;
    border-radius: 99px;
    padding: 6px 16px;
    border: none;
    color: #fff;
    display: block;
  }
  .addGuestBtn,
  .disabledAddGuestBtn {
    display: none;
  }
  .addGuestBtnSmall {
    background: #76c437;
    color: #fff;
    font-weight: bold;
  }
  .disabledAddGuestBtnSmall {
    background: rgba(118, 196, 55, 0.5);
  }
  .disabledAddGuestBtnSmall:hover {
    color: #fff;
    cursor: not-allowed;
  }
  .addGuestBtnSmall:hover,
  .addGuestBtnSmall:focus {
    box-shadow: 0px 4px 8px rgba(58, 58, 68, 0.16),
      0px 8px 16px rgba(90, 91, 106, 0.16);
    color: #fff;
  }
  .addGuestBtn:focus {
    background-color: #1c9740;
  }
  .search {
    width: calc(100% - 40px);
    max-width: 250px;
  }
  .filterSmall {
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .filter {
    display: none;
  }

    // Update grid layout for mobile

  .entryContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 2fr;
    grid-template-areas:
    "one three"
    "one three"
    "two two"
    "four four";
    column-gap: 8px;
  }

  // Update actions section layout for mobile

  .entryActionsSection {
    margin: 8px 0px;
    min-width: none;
    justify-content: space-between;
  }

  .entryActionButton {
    display: inline-flex;
  }
}

@include base.for-tablet-portrait-up {
  .entryGuestSection {
    max-width: 140px;
  }
  .entryFieldsSection {
    max-width: 140px;
    overflow-x: hidden;
  }
  .entryMobileActionsSection {
    display: none;
  }
  .entrySingleField {
    flex-direction: column;
    align-items: flex-start;
    align-self: normal;

    .entrySingleFieldTitle {
      margin-right: none;
      margin-bottom: 4px;
    }
    .entrySingleFieldValue {
      max-width: 75px;
    }
  }
}

@include base.for-tablet-landscape-up {
  .entryGuestSection {
    max-width: 300px;
  }
  .entryFieldsSection {
    max-width: 300px;
    overflow-x: auto;
  }
  .entrySingleField {
    flex-direction: row;
    column-gap: 4px;

    .entrySingleFieldTitle {
      margin-bottom: none;
      align-self: center;
    }
    .entrySingleFieldValue {
      max-width: fit-content;
    }
  }
}

@include base.for-desktop-up {
  .entryFieldsSection {
    max-width: 500px;
  }
  .entrySingleField {
    flex-direction: row;
    column-gap: 4px;

    .entrySingleFieldTitle {
      margin-bottom: none;
      align-self: center;
    }
  }
}

// cleanup responsive between breakpoints
// when sidebar is open and we're below 1406, entry action button text overflows
.hideBelow1406 {
  display: block;

  @media screen and (max-width: 1406px) {
    display: none;
  }
}
//* TODO: old stuff clean up
.guestAction {
  border: 1px solid #bcbcbc;
  align-self: center;
  background-color: #fff;
  padding: 3px 6px;
}

.star {
  position: absolute;
  width: 40px;
}
.popper {
  width: 200px;
  background-color: #fff;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(58, 58, 68, 0.16),
    0px 8px 16px rgba(90, 91, 106, 0.16);
  border-radius: 8px;
}
.popper p {
  border-radius: 4px;
}
.popper p:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.popper p:hover::after {
  content: url("/icons/right-filled-arrow.svg");
  position: absolute;
  right: 15px;
}

.queueXL {
  display: inline-flex;
}

.filterSearchXL {
  min-width: 400px;
}

.button-is-filtered {
  background: #c9f1d5 !important;
  width: auto;
  max-width: none;
  white-space: nowrap;
}

.searchBarItem {
  height: 40px;
}

.location-picker-container select {
  margin-bottom: 0;
}

.wait-time-label {
  font-size: 10px;
  color: #bcbcbc;
}

.mobileSearchContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 78px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  z-index: 100;
}

.mobileSearchContainer button {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 10px;
}

.mobileSearchInputContainer {
  position: relative;
  flex: 1;
}

.mobileSearchInputContainer input {
  height: 100%;
  width: 100%;
  border: none !important;
  border-radius: 4px;
  background-color: #ededed;
  padding-left: 36px;
  outline: none;
}

.mobileSearchIconContainer {
  display: flex;
  align-items: center;
}

.historySwitchText {
  min-width: 60px;
}

.paginationWrapper {
  font-size: 0.875rem;
  text-align: center;
}

.paginationHeader {
  font-weight: 600;
  margin: 16px 0px 8px 0px;
}

.pagination {
  list-style-type: none;
  font-weight: 600;
  margin-bottom: 16px;
}

.pagination li {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 99px;
  min-width: 30px;
  cursor: pointer;
}

.activePaginationItem {
  background-color: #005BFF;
  color: white;
}

li:not(.activePaginationItem):hover {
  background-color: #ececec;
}

@media (max-width: 768px) {
  .queueXL {
    width: 100%;
  }

  .searchBarItem {
    width: 54px;
    height: 56px;
    border: 1px solid var(--colors-green);
    border-radius: var(--radii-1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1025px) {
  .main {
    overflow: auto;
  }
  .queueWrapper {
    flex-direction: column;
  }
  .queueWrapper > div {
    width: 100%;
    overflow-x: auto;
    justify-content: flex-start !important;
  }
  .search {
    width: 30vw;
  }
  .cfText {
    display: none;
    color: red;
  }
  .guestAction {
    margin-left: 10px;
  }
}