@use '@/styles/Colors.module.scss';

$div: ".intlPhoneContainer";

.intlPhoneContainer {
  border: 1px solid rgb(189, 189, 189);
  border-radius: var(--radii-1);
}

.intlPhoneInput {
  width: calc(100% - 46px);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0;
  height: 40px !important;
  font-family: 'Montserrat, Helvetica, Arial, sans-serif';
  font-size: 16px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
  border: none !important;
  &:focus {
    outline: auto Colors.$blue;
  }
}

.active {
  border: 2px solid Colors.$blue;
}

.btnActive {
  border: none !important;
  outline: none;
}

.error {
  border: 2px solid Colors.$red;
}

.btnError {
  outline: auto Colors.$red;
}
.btnCountrySelector {
  &:active {
    outline: auto Colors.$red;
  }
}

.errorsContainer {
  font-size: var(--fontSizes-xs);
  height: 1rem;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// styles for Business Create form
// still based on older styling
.bizCreateIntlPhoneBoxWrapper {
  margin-top: 4px;
}

.bizCreateIntlPhoneErrMsg {
  display: none;
}

.bizCreateIntlPhoneLabel {
  font-family: "Montserrat", "Helvetica", "Arial", "sans-serif" !important;
  margin-bottom: 0;
  display: block;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 90%;
    right: 0;
    background-color: white;
    z-index: 9;
  }
}
.bizCreateIntlPhoneBtn {
  padding-left: 14px !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}
.bizCreateIntlPhoneInput {
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
  padding-right: 14px !important;
  font-size: 16px !important;
  font-family: "Montserrat", "Helvetica", "Arial", "sans-serif" !important;
}



