@use 'Colors.module.scss';

.form {
  background-color: #ffffff;
  border-radius: 8px;
  max-width: 310px;
  padding: 2rem;
}

.inputField {
  margin-bottom: var(--space-1);
}

.inputField input {
  &:focus {
    outline: Colors.$blue;
  }
}

input.error {
  border: 2px solid Colors.$red;
}

.label {
  letter-spacing: 2.75;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 4;
  margin-left: 0.5rem;
}

.checkboxWrapper {
  margin: 0.75rem 0 0.25rem -3px;
  padding-left: 4px;
}

.checkboxLabel {
  @extend .label;
  display: inline;
  font-size: 1rem;
  font-weight: 400;

  & a,
  a:visited {
    color: Colors.$slate;
    text-decoration: none;
    font-weight: bold;
  }
}

.checkboxInputLabel {
  @extend .checkboxLabel;
  display: block;
}

.checkboxError {
  border-bottom: 2px solid rgba(255, 0, 0, 0.5);
  border-right: 2px solid rgba(255, 0, 0, 0.5);
}

.fileUploadInputBlock {
  background: Colors.$gray-light;
  display: flex;
  max-width: fit-content;
  padding: 20px 10px;
  margin: 1rem 0;
  border-radius: 1rem;
  font-size: 0.75rem;
  align-content: center;
}

.switchContainer {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
}

.switchLabel {
  display: flex;
  margin-left: 0.25rem;
  font-weight: 500;
}
