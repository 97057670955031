.pill,
.disabled,
.active {
  border-radius: 99px;
  height: 2.5rem;
  padding: 4px 16px;
  text-align: center;
  margin-bottom: 8px;
  background-color: transparent;
  outline: none;
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: "14px";
}
.pill {
  background-color: #F0F0F0;
  color: #1D1D1D;
  font-weight: 600;
  cursor: pointer;
}
.active {
  background-color: #202B7E;
  color: #fff;
}
.disabled {
  background-color: #F0F0F0;
  color: #757575;
  cursor: not-allowed;
}
.disabled:hover {
  cursor: not-allowed;
}
